import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "./staticPages.module.scss"
import Collapsible from "elements/Collapsible"

export const renderStaticPage = ({ pageContent, type }) => {
  switch (type.toUpperCase()) {
    case "FREQUENTLY ASKED QUESTIONS":
      return (
        <div className={styles["staticContent"]}>
          {pageContent.map((section) => {
            if (section.title !== "Contact") {
              const sectionItems = section?.subsection.map((subsection) => (
                <Collapsible title={subsection?.title}>
                  {documentToReactComponents(
                    JSON.parse(subsection.content.raw)
                  )}
                </Collapsible>
              ))
              return (
                <div>
                  <h4>{section.title}</h4>
                  {sectionItems}
                </div>
              )
            } else {
              return (
                <div>
                  <p className="is-size-3 has-text-weight-bold mb-1">
                    {section.title}
                  </p>
                  <div className={styles["staticContent"]}>
                    {documentToReactComponents(
                      JSON.parse(section.subsection[0].content.raw)
                    )}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )
    default:
      return (
        <>
          <div className={styles["staticContent"]}>
            {documentToReactComponents(JSON.parse(pageContent.content.raw))}
          </div>
          {pageContent.collapsibleSection?.map((item) => (
            <Collapsible title={item?.title}>
              {documentToReactComponents(JSON.parse(item.content.raw))}
            </Collapsible>
          ))}
        </>
      )
  }
}
